import '@astrojs/web-vitals/client-script';
import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://3fbd4ccb1414c696824c053ccc2a2f65@o4504217177292800.ingest.us.sentry.io/4507727000698880",
  debug: false,
  environment: "production",
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 0.5,
  integrations: [Sentry.browserTracingIntegration()],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
});